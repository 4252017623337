import React,{useState,useEffect} from 'react'
import styles from '../../Styles/elma8zon.module.css'
import Sidebar from '../../Layout/Sidebar'
import ReactApexChart from "react-apexcharts";
import { Col, Container, Row } from 'react-bootstrap';
import FirstCircle from './FirstCircle';
import SecondCircle from './SecondCircle';
import ThirdCircle from './ThirdCircle';
import FirstColumn from './FirstColumn';
import SecondColumn from './SecondColumn';
import ThirdColumn from './ThirdColumn';
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from "../../config";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
const EmployeeChart = () => {
  const { token } = useSelector((state) => state.user);
  const[load,setLoad]=useState(true)


const [Age,setAge]=useState([])
const [AgeNum,setAgeNum]=useState('')
const [nationalityName,setnationalityName]=useState([])
const[nationalityAge,setnationalityAge]=useState([])
const[sexName,setsexName]=useState([])
const[sexAge,setsexAge]=useState([])
const[religionName,setreligionName]=useState([])
const[religionAge,setreligionAge]=useState([])
const[qualifications ,setqualifications] = useState([])
const[governorate,setgovernorate]=useState([])
console.log(Age)
/* const options = {
  chart: {
      type: 'column'
  },
  title: {
      text: 'متوسط الاعمار'
  },
  subtitle: {
      text: ''
  },
  xAxis: {
      type: 'category',
      title: {
        text: 'Age'
    },
      labels: {
          autoRotation: [-45, -90],
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
      }
  },
  yAxis: {
      min: 0,
      title: {
          text: ''
      }
  },
  legend: {
      enabled: false
  },
  tooltip: {
      pointFormat: ' <b>{point.y} </b> Employee'
  },
  series: [{
      name: 'Population',
      colors: [
          '#9b20d9', '#9215ac', '#861ec9', '#7a17e6', '#7010f9', '#691af3',
          '#6225ed', '#5b30e7', '#533be1', '#4c46db', '#4551d5', '#3e5ccf',
          '#3667c9', '#2f72c3', '#277dbd', '#1f88b7', '#1693b1', '#0a9eaa',
          '#03c69b',  '#00f194'
      ],
      colorByPoint: true,
      groupPadding: 0,
      data: Age,
      dataLabels: {
          enabled: true,
          rotation: -90,
          color: '#000',
          inside: true,
          verticalAlign: 'top',
          format: '{point.y}', // one decimal
          y: -30, // 10 pixels down from the top
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
      }
  }]
}; */
const chartData = Age.map(item => ({
  name: item[0], // formatted age
  y: item[1] // employee number
}));
const options = {
  chart: {
      type: 'column'
  },
  title: {
      text:`متوسط الاعمار ( ${AgeNum} )`
  },
  subtitle: {
      text: ''
  },
  xAxis: {
      categories: Age.map(item => item[0]), // use formatted age strings
      labels: {
          autoRotation: [-45, -90],
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
      }
  },
  yAxis: {
      min: 0,
      title: {
          text: 'Number of Employees'
      }
  },
  legend: {
      enabled: false
  },
  tooltip: {
      pointFormat: ' <b>{point.y} </b> Employees'
  },
  series: [{
      name: 'Population',
      colorByPoint: true,
      groupPadding: 0,
      data: chartData, // use the prepared chart data
      dataLabels: {
          enabled: true,
          rotation: -90,
          color: '#000',
          inside: true,
          verticalAlign: 'top',
          format: '{point.y}', // Display the number of employees
          y: -30,
          style: {
              fontSize: '13px',
              fontFamily: 'Verdana, sans-serif'
          }
      }
  }]
};
  useEffect(() => {
  
    axios
      .get(`${Environment.baseURL}/api/EmployeeAgeGroupes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const sortedData = response.data.employeeAgeGroupes
        .sort((a, b) => b.empNo - a.empNo)
        .map(q => [`${q.age} age`, q.empNo]);

      // Set the Age state with the sorted data
      setAge(sortedData);
      setAgeNum(response.data.empAverageAge.toFixed(2))
        setnationalityName(response.data.employeeAgeGroupesByNationalities.map((item) => item.nationalityName))
        setnationalityAge(response.data.employeeAgeGroupesByNationalities.map((item) => item.empNo))
        setsexName(response.data.employeeAgeGroupesBySex.map((item) => item.sexName))
        setsexAge(response.data.employeeAgeGroupesBySex.map((item) => item.empNo))
        setreligionName(response.data.employeeAgeGroupesByReligions.map((item) => item.religionName))
        setreligionAge(response.data.employeeAgeGroupesByReligions.map((item) => item.empNo))
setqualifications(response.data.employeeAgeGroupesByQualifications)
setgovernorate(response.data.employeeAgeGroupesByGovernorates)
        setLoad(false)
      })
      .catch((err) => {
        console.log(err.response);
      });
      setLoad(true)

  }, []);
  const {hremplyeeStatistics} = useSelector(state => state.cart)

  return (
    <>
<section className={`${styles.home}`}>
       <Sidebar active="sho2on" />  
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}>  احصائيات الموظفيين </h2>
          {
            hremplyeeStatistics?
            <Container>
            <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
              />
              <Row className='mt-5'>
                  <Col xxl='4'>
                  <FirstCircle qty={nationalityAge} name={nationalityName}/>
                  </Col>
                  <Col xxl='4'>
                  <SecondCircle qty={sexAge} name={sexName}/>
                  </Col>
                  <Col xxl='4'>
                  <ThirdCircle qty={religionAge} name={religionName}/>
                  </Col>
              </Row>
         <div className='mt-5'>
                  <FirstColumn qualifications={qualifications}/>
                  </div>
                  <div className='mt-5'>
                 <SecondColumn governorate={governorate}/>
                 </div>
              </Container>
              :
              <h1 className={`${styles.h1} `}>
              غير مفعلة في هذا الحساب
            </h1>
          }
       
          </div>
          </section>
    </>
  )
}

export default EmployeeChart