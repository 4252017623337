import React, { useEffect, useState } from "react";
import Sidebar from '../../Layout/Sidebar'
import styles from '../../Styles/elma8zon.module.css'
import PieQty from './PieQty'
import { Button, Col, Container, Row } from 'react-bootstrap'
import PiePrice from './PiePrice'
import FirstTable from './FirstTable'
import SecondTable from './SecondTable'
import ThirdTable from './ThirdTable'
import FourthTable from './FourthTable'
import FivthTable from './FivthTable'
import { useSelector } from "react-redux";
import axios from "axios";
import Environment from "../../config";

const ElMa8zon = () => {
  const { token } = useSelector((state) => state.user);
  const[stockAnalysis,setstockAnalysis]=useState({})
  const[stockQuantityByBranches,setstockQuantityByBranchess]=useState([])
  const[stockQuantityNameByBranches,setstockQuantityNameByBranches]=useState([])
  const[stockMoneyByBranches,setstockMoneyByBranches]=useState([])
  const[stockMoneyNameByBranches,setstockMoneyNameByBranches]=useState([])
  const[totalStockAnalysisByBranches,settotalStockAnalysisByBranches]=useState([])
const[totalStockAnalysisByItems,settotalStockAnalysisByItems]=useState([])
const[totalStockAnalysisByVendors,settotalStockAnalysisByVendors]=useState([])
const[totalStockAnalysisByCategories,settotalStockAnalysisByCategories]=useState([])
const[totalStockAnalysisBySeasons,settotalStockAnalysisBySeasons]=useState([])
  useEffect(() => {

    axios
      .get(`${Environment.baseURL}/api/StockAnalysis`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setstockAnalysis(response.data.stockAnalysis)
        setstockQuantityByBranchess(response.data.stockQuantityByBranches.map(branch => branch.totalQty))
        setstockQuantityNameByBranches(response.data.stockQuantityByBranches.map(branch => branch.branchName))
        setstockMoneyByBranches(response.data.stockMoneyByBranches.map(branch => branch.totalMoney.toFixed(2)))
        setstockMoneyNameByBranches(response.data.stockMoneyByBranches.map(branch => branch.branchName))
        settotalStockAnalysisByBranches(response.data.totalStockAnalysisByBranches)
        settotalStockAnalysisByItems(response.data.totalStockAnalysisByItems)
        settotalStockAnalysisByVendors(response.data.totalStockAnalysisByVendors)
        settotalStockAnalysisByCategories(response.data.totalStockAnalysisByCategories)
        settotalStockAnalysisBySeasons(response.data.totalStockAnalysisBySeasons)
     
      })
      .catch((err) => {
        console.log(err.response);
      });


  }, []);
  const {stockStatistics} = useSelector(state => state.cart)

  return (
    <>
     <section className={`${styles.home}`}>
       <Sidebar active="ma8zoon" />  
        <div className={`${styles.homeContainer}`}>
          <h2 className={`${styles.h2} m-4`}> احصائيات المخزون </h2>
{
  stockStatistics?
  <>
  <div className={`${styles.activities}`} dir="rtl">
                
  <div className={`${styles.activities__body}`}>
    <p>إجمالي القيمة  </p>
    <h2>
   
    {stockAnalysis.totalMoney !== undefined
        ? stockAnalysis.totalMoney.toFixed(2)
        : "Loading..."}
    </h2>
  </div>
  <div className={`${styles.activities__body}`}>
    <p> إجمالي الكمية</p>
    <h2>
    {stockAnalysis.totalQty !== undefined
        ? stockAnalysis.totalQty.toFixed(2)
        : "Loading..."}
        </h2>
  </div>
  
</div>

<div className={`${styles.pie__row}`}>
   
<PieQty qty={stockQuantityByBranches} name={stockQuantityNameByBranches}/>


<PiePrice qty={stockMoneyByBranches} name={stockMoneyNameByBranches}/>

</div>
<Container>
    <div className='mt-5'>
<FirstTable data={totalStockAnalysisByBranches}/>
</div>
</Container>
<Container>
<div className='mt-5'>
<SecondTable data={totalStockAnalysisByItems}/>
</div>
</Container>
<Container>
<div className='mt-5'>
<ThirdTable data={totalStockAnalysisByVendors}/>
</div>
</Container>
<Container>
<Row className='mt-5'>
    <Col xxl='6'>
    <FourthTable data={totalStockAnalysisByCategories}/>
    </Col>
    <Col xxl='6'>
    <FivthTable data={totalStockAnalysisBySeasons}/>
    </Col>
</Row>
</Container>
</>
:
<h1 className={`${styles.h1} `}>
غير مفعلة في هذا الحساب
</h1>
}
    
          </div>
          </section>
    </>
  )
}

export default ElMa8zon